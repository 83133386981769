import React, { useEffect, useMemo, useState } from "react";
import { Divider, makeStyles, useTheme } from "@rneui/themed";
import { View, ScrollView, Platform, RefreshControlProps } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import Feather from "react-native-vector-icons/Feather";
import { DateTime } from "luxon";

import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";

import ScreenContainer from "../../components/ui/ScreenContainer";
import useViewStyles from "../../components/ui/styles/useViewStyles";
import Text from "../../components/ui/Text";
import ReadingComponent from "../../components/home/ReadingComponent";
import Spacing from "../../components/ui/Spacing";
import YourCareTeam from "../../components/home/YourCareTeam";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";

import { useGetLatestReadingsByMemberQuery } from "common/services/ReadingsService";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import RefreshControl from "../../components/ui/RefreshControl";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import { AccessibilityHelper_getAccessibilityProps } from "../../helpers/AccessibilityHelper";
import TouchableOpacity from "../../components/ui/TouchableOpacity";
import { useGetExistingRoomQuery } from "common/services/VideoService";
import { isFalsy } from "common/helpers/helpers";
import Button from "../../components/ui/Button";
import RoomType from "common/types/RoomType";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { DashboardStackParamList } from "../../navigation/DashboardStackNavigator";
import HeaderIconText from "../../components/home/HeaderIconText";
import useAppState from "react-native-appstate-hook";
import { Linking_openURL } from "common/helpers/LinkingHelper";
import { dispatch } from "common/redux";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import { useGetPatientCalendarEventsQuery } from "common/services/CalendarService";
import AppointmentCard from "../../components/ui/banners/AppointmentCard";
import NoAppointmentCard from "../../components/ui/banners/NoAppointmentCard";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import ResponsiveBreakpoints from "../../constants/ResponsiveBreakpoints";
import useGetExpiringConsents from "../../hooks/useGetExpiringConsents";

interface ScrollViewProps {
  children: React.ReactNode[];
  refreshControl?: React.ReactElement<RefreshControlProps>;
}

const MY_READINGS_SCREEN = "Readings";

//LinearGradient is required (ios only) for bouncing effect on scrollview for proper background handling.
const BounceBackgroundScrollView = ({
  children,
  refreshControl
}: ScrollViewProps) => {
  const { theme } = useTheme();
  const styles = useStyles();

  if (Platform.OS === "ios" && theme.colors.white !== theme.colors.background) {
    return (
      <LinearGradient
        colors={[
          theme.colors.white,
          theme.colors.white,
          theme.colors.background,
          theme.colors.background
        ]}
      >
        <ScrollView
          contentContainerStyle={styles.scrollView}
          refreshControl={refreshControl}
        >
          {children}
        </ScrollView>
      </LinearGradient>
    );
  } else {
    return (
      <ScrollView
        contentContainerStyle={styles.scrollView}
        refreshControl={refreshControl}
      >
        {children}
      </ScrollView>
    );
  }
};

type ScreenProp = NavigationProp<DashboardStackParamList>;
const OngoingCall = ({ room }: { room: RoomType }) => {
  const styles = useStyles();
  const navigation = useNavigation<ScreenProp>();

  const [currentDate, setCurrentDate] = useState<DateTime>(DateTime.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(DateTime.now());
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const startedDate = DateTime.fromISO(room.start_datetime);

  const duration = currentDate.diff(startedDate).toFormat("mm:ss");

  return (
    <View>
      <HeaderIconText
        text={`${LocalizedStrings.screens.home.ongoingCall} ${duration}`}
      />

      <Spacing vertical={2} />
      <Button
        title={LocalizedStrings.common.join}
        containerStyle={styles.joinCallButtonContainer}
        buttonStyle={styles.joinCallButton}
        onPress={() => navigation.navigate("VideoCall", { code: room.code })}
      />
    </View>
  );
};

const GRID_ITEMS_WIDTH = 300;

const HomeScreen = ({ navigation }) => {
  const { type } = useScreenType();
  const { theme } = useTheme();
  const styles = useStyles();
  const viewStyles = useViewStyles();
  const textStyles = useTextStyles();
  const { appState } = useAppState();

  const [width, setWidth] = useState<number>();

  const now = useMemo(() => {
    return DateTime.now();
  }, []);

  const endDate = now.plus({ days: 7 }).endOf("day");

  const { data: patient, isLoading: patientLoading } =
    useGetAuthenticatedMember(false, [
      MemberLinkedEntitiesEnum.PROVIDER,
      MemberLinkedEntitiesEnum.NURSE
    ]);

  const { isExpiringSoon } = useGetExpiringConsents();

  const skipExistingRoom = isFalsy(patient) || appState !== "active";
  const { data: existingRoom, refetch: refetchExistingRoom } =
    useGetExistingRoomQuery(
      {
        member_id: patient?.patient?.patient_id
      },
      {
        skip: skipExistingRoom,
        pollingInterval: 30000
      }
    );

  const {
    data: lastReading,
    isFetching: isReadingsFetching,
    isLoading: isReadingsLoading,
    isSuccess,
    refetch: refetchReadings
  } = useGetLatestReadingsByMemberQuery(
    {
      memberId: patient?.patient?.patient_id,
      devices: [DeviceTrendParam.GLUCOSE, DeviceTrendParam.BLOOD_PRESSURE]
    },
    { skip: patient === undefined }
  );

  const {
    data: calendarEventsData,
    isFetching: isCalendarEventsFetching,
    isLoading: isCalendarEventsLoading,
    refetch: refetchCalendarEvents
  } = useGetPatientCalendarEventsQuery(
    {
      patient_id: patient?.patient.patient_id,
      startdate: now.startOf("day"),
      enddate: endDate
    },
    { skip: patient === undefined }
  );

  const isAppointmentsLoading = patientLoading || isCalendarEventsLoading;

  const lastBPReading = lastReading
    ? lastReading[DeviceTrendParam.BLOOD_PRESSURE]
    : undefined;
  const lastGlucoseReading = lastReading
    ? lastReading[DeviceTrendParam.GLUCOSE]
    : undefined;

  const isGrid =
    type !== ScreenTypeEnum.PHONE &&
    width > ResponsiveBreakpoints.HOME_SCREEN_GRID_BREAKPOINT;

  const gridColumnsCount = isGrid ? Math.floor(width / GRID_ITEMS_WIDTH) : 2;

  const { nextAppointments, upcomingAppointments } = useMemo(() => {
    if (!calendarEventsData || calendarEventsData.length === 0)
      return {
        nextAppointments: []
      };

    const filteredData = calendarEventsData.filter(
      (item) => DateTime.fromISO(item.enddate) > DateTime.now()
    );

    return {
      nextAppointments: filteredData.slice(0, gridColumnsCount),
      upcomingAppointments: filteredData.slice(gridColumnsCount)
    };
  }, [calendarEventsData, gridColumnsCount]);

  const READINGS_DATA = useMemo(() => {
    const hasBloodPressure = lastBPReading !== undefined;
    const hasGlucose = lastGlucoseReading !== undefined;

    return [
      {
        key: "reading_blood_pressure",
        header: LocalizedStrings.screens.home.readingsBP,
        maxFont: 1.1,
        value: hasBloodPressure
          ? lastBPReading.systolic + "/" + lastBPReading.diastolic
          : undefined,
        measureUnit: "mmHg",
        loading: isReadingsLoading || patientLoading,
        dateTime: hasBloodPressure
          ? DateTime.fromSeconds(lastBPReading.measure_timestamp)
          : undefined,
        onPress: () => {
          navigation.navigate(MY_READINGS_SCREEN, {
            screen: "BloodReadingDetails",
            initial: false
          });
        }
      },
      {
        key: "reading_pulse",
        header: LocalizedStrings.screens.home.readingsPulse,
        maxFont: 1.3,
        value: hasBloodPressure ? lastBPReading.pulse : undefined,
        measureUnit: "bpm",
        loading: isReadingsLoading || patientLoading,
        dateTime: hasBloodPressure
          ? DateTime.fromSeconds(lastBPReading.measure_timestamp)
          : undefined,
        onPress: () => {
          navigation.navigate(MY_READINGS_SCREEN, {
            screen: "BloodReadingDetails",
            initial: false
          });
        }
      },
      {
        key: "reading_glucose",
        header: LocalizedStrings.screens.home.readingsGlucose,
        maxFont: 1.3,
        value: hasGlucose ? lastGlucoseReading.glucose : undefined,
        measureUnit: "mg/dL",
        dateTime: hasGlucose
          ? DateTime.fromSeconds(lastGlucoseReading.measure_timestamp)
          : undefined,
        loading: isReadingsLoading || patientLoading,
        onPress: () => {
          navigation.navigate(MY_READINGS_SCREEN, {
            screen: "GlucoseReadingDetails",
            initial: false
          });
        }
      }
    ];
  }, [lastBPReading, lastGlucoseReading, isReadingsLoading, patientLoading]);

  const handleLearnMore = () => {
    Linking_openURL("https://www.copilotiq.com", dispatch);
  };

  const noReadingsDoneLoading = isSuccess && READINGS_DATA.length === 0;

  const handleRefresh = () => {
    if (patient !== undefined) refetchReadings();
    if (!skipExistingRoom) refetchExistingRoom();
    if (patient) refetchCalendarEvents();
  };

  const isRefreshControlLoading =
    (!isReadingsLoading && isReadingsFetching) ||
    (!isCalendarEventsLoading && isCalendarEventsFetching);

  return (
    <ScreenContainer
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout;
        setWidth(width);
      }}
    >
      <BounceBackgroundScrollView
        refreshControl={
          <RefreshControl
            refreshing={isRefreshControlLoading}
            onRefresh={handleRefresh}
          />
        }
      >
        <View style={[styles.headerContainer, viewStyles.navigationBarRounded]}>
          <View style={styles.row}>
            {patientLoading ? (
              <ActivityIndicator style={styles.flex1} size="small" />
            ) : (
              <Text
                h2
                allowFontScaling={false}
                {...AccessibilityHelper_getAccessibilityProps("Member Name")}
                testID="patientName"
              >
                {patient
                  ? `${LocalizedStrings.common.hello}, ${patient.patient.first}!`
                  : " "}
              </Text>
            )}

            {/*<IconButton
              icon="bell"
              onPress={() => navigation.navigate('Notification')}
              type="outline"
              color={theme.colors.darkGreyBlue}
              iconColor={theme.colors.darkGreyBlue}
              iconSize={NOTIFICATION_SIZE / 2}>
              <Badge status="error" containerStyle={styles.badge} />
  </IconButton>*/}
          </View>

          {!noReadingsDoneLoading && (
            <Text
              bodySmall
              style={[styles.textCenter, textStyles.colorGreyBlue]}
            >
              {LocalizedStrings.screens.home.latestReadings}
            </Text>
          )}
          {!noReadingsDoneLoading && (
            <Spacing vertical={READINGS_MARGIN_TOP / 15} />
          )}

          {noReadingsDoneLoading && (
            <>
              <Text body>{LocalizedStrings.screens.home.noReadingsText}</Text>
              <TouchableOpacity onPress={handleLearnMore}>
                <Text>
                  <Text link>{LocalizedStrings.screens.home.learnMore}</Text>{" "}
                  <Feather
                    name="arrow-right"
                    size={16}
                    color={theme.colors.primary}
                  />
                </Text>
              </TouchableOpacity>
            </>
          )}
        </View>

        {!noReadingsDoneLoading && (
          <View style={styles.readingAbsoluteContainer}>
            <View style={styles.readingInnerContainer}>
              {READINGS_DATA.map(
                ({
                  header,
                  value,
                  maxFont,
                  measureUnit,
                  dateTime,
                  key,
                  onPress,
                  loading
                }) => (
                  <ReadingComponent
                    key={key}
                    testID={key}
                    header={header}
                    maxFont={maxFont}
                    value={value}
                    measureUnit={measureUnit}
                    dateTime={dateTime}
                    onPress={onPress}
                    loading={loading}
                  />
                )
              )}
            </View>
          </View>
        )}

        <View
          style={[
            styles.contentContainer,
            !noReadingsDoneLoading && {
              marginTop: READINGS_MARGIN_TOP - 40
            }
          ]}
        >
          {!noReadingsDoneLoading && (
            <View>
              <Text
                bodySmall
                style={[styles.textCenter, textStyles.colorGreyBlue]}
              >
                {LocalizedStrings.screens.home.tapReadingToViewMore}
              </Text>
            </View>
          )}

          <Divider style={styles.divider} color={theme.colors.lightGreen} />

          {existingRoom && <OngoingCall room={existingRoom} />}

          {/*<LandingBanner
            title="DAY 1"
            subTitle="Start your journey"
            description="Review the goals you set with your nurse."
            buttonTitle="My Goals"
            onButtonPress={() => navigation.navigate('MyGoals')}
          />
          <LandingBanner
            variant="secondary"
            title="DAY 2"
            subTitle="Schedule an Appointment"
            description="Check your nurse's availability to schedule an appointment for next week."
            buttonTitle="Book Appointment"
            onButtonPress={() => navigation.navigate('YourCareTeam')}
          />*/}

          {isExpiringSoon && (
            <View
              style={[
                viewStyles.cardContainer,
                styles.cardContainer,
                { backgroundColor: theme.colors.error }
              ]}
            >
              <Text h4 style={textStyles.colorWhite}>
                Expiring Legal Forms
              </Text>
              <Text body style={textStyles.colorWhite}>
                Your legal forms will soon expire. Please accept the new
                consents in order to continue using the app.
              </Text>
              <Spacing vertical={2} />
              <Button
                variant="cancel"
                onPress={() => navigation.navigate("MissingLegalForms")}
                title={"Accept Legal Forms"}
              />
            </View>
          )}

          <View style={isGrid && styles.flex1}>
            <HeaderIconText
              text="Next Appointments"
              linkText="See All"
              onLinkPress={() => navigation.navigate("MyAppointments")}
              {...AccessibilityHelper_getAccessibilityProps("See All")}
            />
            <View style={[styles.cardContainer, isGrid && styles.gridRow]}>
              {nextAppointments?.map((item, index) => (
                <View
                  key={item.event_id}
                  style={[styles.gridItemWidth, isGrid && styles.flex1]}
                >
                  <AppointmentCard
                    appointment={item}
                    canConfirmOrReschedule={index <= 1}
                  />
                </View>
              ))}
            </View>
            {isAppointmentsLoading === false &&
              nextAppointments?.length === 0 && <NoAppointmentCard days="7" />}
            {isAppointmentsLoading && <ActivityIndicator />}
          </View>

          {/*isGrid && (
              <View style={isGrid && styles.flex1}>
                <YourCareTeam />
              </View>
            )*/}

          {upcomingAppointments && upcomingAppointments?.length > 0 && (
            <View>
              {nextAppointments?.length <= gridColumnsCount && (
                <HeaderIconText
                  text="Upcoming"
                  linkText="See All"
                  {...AccessibilityHelper_getAccessibilityProps("See All")}
                  onLinkPress={() => navigation.navigate("MyAppointments")}
                />
              )}

              <Spacing vertical={2} />
              <View style={[styles.cardContainer, isGrid && styles.gridRow]}>
                {upcomingAppointments?.map<React.ReactNode>(
                  (appointment, index) => {
                    return (
                      <View
                        key={appointment.event_id}
                        style={[styles.gridItemWidth, isGrid && styles.flex1]}
                      >
                        <AppointmentCard
                          appointment={appointment}
                          canConfirmOrReschedule={gridColumnsCount + index <= 1}
                        />
                      </View>
                    );
                  }
                )}
              </View>
            </View>
          )}

          <YourCareTeam />
        </View>

        {/* <GoalProgress /> */}

        {/*<View style={styles.contentContainer}>
          <YourCareTeam />
        </View>*/}
      </BounceBackgroundScrollView>
    </ScreenContainer>
  );
};

const NOTIFICATION_SIZE = 32;

const READINGS_MARGIN_TOP = 110;

const MARGIN = 20;

const useStyles = makeStyles((theme) => {
  return {
    flex1: {
      flex: 1
    },
    container: {
      backgroundColor: theme.colors.white
    },
    gridRow: {
      flexWrap: "wrap",
      flexDirection: "row",
      gap: 10
    },
    gridItemWidth: {
      minWidth: GRID_ITEMS_WIDTH
    },
    cardContainer: {
      gap: 10
    },
    scrollView: {
      backgroundColor: theme.colors.background
    },
    headerContainer: {
      backgroundColor: theme.colors.white,
      padding: MARGIN,
      gap: MARGIN
    },
    badge: {
      position: "absolute",
      top: NOTIFICATION_SIZE / 8,
      right: NOTIFICATION_SIZE / 8
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      height: 40
    },
    readingAbsoluteContainer: {
      position: "absolute",
      left: 0,
      right: 0,
      top: READINGS_MARGIN_TOP
    },
    readingInnerContainer: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      gap: MARGIN,
      marginHorizontal: MARGIN
    },
    contentContainer: {
      margin: MARGIN,
      gap: MARGIN
    },
    divider: {
      width: 100,
      alignSelf: "center"
    },
    textCenter: {
      alignSelf: "center"
    },
    joinCallButtonContainer: {
      maxWidth: 500
    },
    joinCallButton: {
      backgroundColor: theme.colors.error
    }
  };
});

export default HomeScreen;
