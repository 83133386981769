import { Platform, ScrollView, View } from "react-native";
import { makeStyles } from "@rneui/themed";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";

import {
  PermissionsHelper_askForPushNotifications,
  PermissionsHelper_hasPushNotification,
  PermissionsHelper_openSettings
} from "common/helpers/PermissionsHelper";
import { RootState, useAppDispatch } from "common/redux";
import { setAuthFlowFinished } from "common/redux/AuthSlice";

import Button from "../../components/ui/Button";
import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import Spacing from "../../components/ui/Spacing";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import StepIndicator from "../../components/ui/StepIndicator";
import { useEffect, useState } from "react";
import useAppState from "react-native-appstate-hook";
import { useSelector } from "react-redux";
import { setNotificationsEnabled } from "common/redux/SettingsSlice";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import { PushNotificationsHelper_getTokenAndStore } from "common/helpers/firebase/PushNotificationsHelper";

const TurnOnNotificationsScreen = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const { appState } = useAppState();

  const insets = useSafeAreaInsets();
  const styles = useStyles(insets);
  const textStyles = useTextStyles();

  const { hasCredentials } = useSelector((state: RootState) => state.auth);

  const [settingsRequired, setSettingsRequired] = useState<boolean>(undefined);

  const finishAuthHandler = (withNotifications: boolean) => {
    if (withNotifications) {
      dispatch(setNotificationsEnabled(true));
    }
    dispatch(setAuthFlowFinished());
  };

  const checkPermissions = () => {
    if (!hasCredentials) return;

    PermissionsHelper_hasPushNotification().then(
      ({ enabled, settingsRequired }) => {
        setSettingsRequired(settingsRequired);
        if (enabled) {
          finishAuthHandler(true);
        }
      }
    );
  };

  const turnOnHandler = () => {
    PermissionsHelper_askForPushNotifications()
      .then((isEnabled) => {
        if (isEnabled) {
          finishAuthHandler(true);
          PushNotificationsHelper_getTokenAndStore();
        } else {
          checkPermissions();
        }
      })
      .catch((error) => {
        if (error.message === "Permission has been denied") {
          setTimeout(() => setSettingsRequired(true), 500);
        }
      });
  };

  useEffect(() => {
    if (appState !== "active") return;

    checkPermissions();
  }, [appState]);

  useEffect(() => {
    if (!hasCredentials) {
      setTimeout(() => {
        navigation.reset(
          {
            index: 0,
            routes: [{ name: "Landing" }]
          },
          500
        );
      });
    }
  }, [hasCredentials]);

  return (
    <ScreenContainer limitWidthTabletScreen disableBottomInsets>
      <StepIndicator size={5} index={4} />

      <View style={styles.container}>
        <ScrollView>
          <View>
            <Text h1 style={textStyles.colorDarkGreyBlue}>
              {LocalizedStrings.screens.turnOnNotifications.header}
            </Text>
            <Spacing vertical={4} />
            <Text bodyLarge style={textStyles.colorGreyBlue}>
              {LocalizedStrings.screens.turnOnNotifications.text1}
            </Text>
            <Spacing vertical={2} />
            <Text bodyLarge style={textStyles.colorGreyBlue}>
              {LocalizedStrings.screens.turnOnNotifications.text2}
            </Text>
          </View>
        </ScrollView>

        <View>
          {settingsRequired ? (
            <>
              <Text bodyLarge style={textStyles.colorError}>
                {LocalizedStrings.screens.turnOnNotifications.rejected}
              </Text>
              <Spacing vertical={4} />
              <Button
                title={
                  LocalizedStrings.screens.turnOnNotifications.openSettings
                }
                onPress={() => PermissionsHelper_openSettings(dispatch)}
                accessibilityLabel={"Open Settings"}
              />
            </>
          ) : (
            <Button
              title={LocalizedStrings.screens.turnOnNotifications.turnOn}
              onPress={turnOnHandler}
              accessibilityLabel={"Turn Notifications On"}
            />
          )}
          <Spacing vertical={2} />
          <Button
            title={LocalizedStrings.screens.turnOnNotifications.later}
            type="outline"
            accessibilityLabel={"Maybe Later"}
            onPress={() => finishAuthHandler(false)}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme, insets: EdgeInsets) => {
  const bottomInsets = insets.bottom + (Platform.OS === "android" ? 10 : 0);
  const marginBottom = Math.max(bottomInsets, 10);

  return {
    container: {
      flex: 1,
      margin: 20,
      marginBottom,
      justifyContent: "space-between"
    }
  };
});

export default TurnOnNotificationsScreen;
